<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input
            placeholder="用户名"
            v-model.trim="formData.userName"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dialogTitle === '新增用户'"
          label="密码"
          prop="password"
        >
          <el-input
            type="password"
            placeholder="密码"
            v-model.trim="formData.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="formData.roleId">
            <el-option
              v-for="(item, index) in roleList"
              :key="index"
              :label="item.name"
              :value="String(item.id)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input
            placeholder="姓名"
            v-model.trim="formData.realName"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            type="tel"
            placeholder="电话"
            v-model.trim="formData.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属区域" prop="city">
          <el-cascader
            v-model="formData.city"
            :options="provinceAndCityData"
            :props="{
              value: 'label',
            }"
          >
          </el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import utils from '@/utils/utils';
import api from '@/api/UserManageApi';
import { provinceAndCityData } from 'element-china-area-data';

export default {
  name: 'AddUserManage',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '新增用户',
      loading: false,
      formData: {
        userName: '',
        password: '',
        roleId: '',
        realName: '',
        phone: '',
        city: '',
      },
      rules: {
        userName: [{ required: true, message: '请输入', trigger: 'change' }],
        password: [{ required: true, message: '请输入', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      roleList: [],
      provinceAndCityData,
    };
  },
  watch: {},
  created() {
    this.getRoleList();
  },
  methods: {
    // 获取角色列表
    getRoleList() {
      api
        .getRoleList({})
        .then(res => {
          this.roleList = res.result.datas;
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            password,
            roleId,
            userName,
            id,
            realName,
            phone,
            city,
          } = this.formData;
          if (this.dialogTitle === '新增用户') {
            api
              .insertUser({
                password,
                roleId,
                userName,
                realName,
                phone,
                city: city.join('/'),
              })
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api
              .updateUser({
                userId: id,
                roleId,
                userName,
                realName,
                phone,
                city: city.join('/'),
              })
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
