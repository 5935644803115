<template>
  <div class="valuation">
    <l-toggle :FormButton="FormButton">
      <el-form ref="elfrom" :model="searchFrom" size="small" label-width="90px">
        <el-row>
          <el-col :xs="24" :sm="12" :lg="6">
            <el-form-item label="用户名">
              <el-input
                v-model.trim="searchFrom.userName"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{ background: '#f2f2f2' }"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="userName"
          label="用户名"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="realName"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="电话"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="city"
          label="所属区域"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="350">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
              >编辑
            </el-button>
            <el-button
              type="warning"
              icon="el-icon-view"
              size="mini"
              @click="editPassword(scope.row)"
              >修改密码
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delData(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </div>
    <!-- 新增层 -->
    <add-user-manage
      ref="AddUserManage"
      @refreshTable="refreshTable"
    ></add-user-manage>
    <edit-password
      ref="EditPassword"
      @refreshTable="refreshTable"
    ></edit-password>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import AddUserManage from './UserManage/AddUserManage.vue';
import api from '@/api/UserManageApi';
import EditPassword from './UserManage/EditPassword.vue';

export default {
  name: 'UserManage',
  components: {
    LToggle,
    AddUserManage,
    EditPassword,
  },
  data() {
    return {
      searchFrom: {
        userName: '',
      },
      FormButton: [
        {
          icon: 'el-icon-search',
          name: '查询',
          type: 'primary',
          loading: false,
          click: this.searchEvent,
        },
        {
          icon: 'el-icon-plus',
          name: '新增',
          type: 'primary',
          click: this.addEvent,
        },
      ],
      tableLoading: false,
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },
    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
  },
  created() {
    this.reqTableData();
  },
  methods: {
    delData(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            const { id } = row;
            api
              .deleteUser({
                id,
              })
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    edit(row) {
      this.$refs.AddUserManage.dialogVisible = true;
      this.$refs.AddUserManage.dialogTitle = '编辑用户';
      this.$nextTick(() => {
        const city = row?.city?.split('/') ?? [];
        this.$refs.AddUserManage.formData = { ...row, city };
      });
    },
    editPassword(row) {
      this.$refs.EditPassword.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.EditPassword.formData.userId = row.id;
      });
    },
    searchEvent() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    addEvent() {
      this.$refs.AddUserManage.dialogVisible = true;
      this.$refs.AddUserManage.dialogTitle = '新增用户';
    },
    refreshTable() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const { userName } = this.searchFrom;
      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api
        .getUserList({
          userName,
          pageNum,
          pageSize,
        })
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
