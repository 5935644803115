<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="100px"
        size="small"
        :rules="rules"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            placeholder="密码"
            v-model.trim="formData.password"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            type="password"
            placeholder="密码"
            v-model.trim="formData.confirmPassword"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/UserManageApi';

export default {
  name: 'EditPassword',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '修改密码',
      loading: false,
      formData: {
        userId: '',
        password: '',
        confirmPassword: '',
      },
      rules: {
        password: [{ required: true, message: '请输入', trigger: 'change' }],
        confirmPassword: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
      },
    };
  },
  watch: {},
  created() {},
  methods: {
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          const { userId, password, confirmPassword } = this.formData;
          if (password !== confirmPassword) {
            this.$message.error('确认密码错误');
            return;
          }
          this.loading = true;
          api
            .modifyPassword({
              userId,
              password: this.$md5(password),
              confirmPassword: this.$md5(confirmPassword),
            })
            .then(() => {
              this.dialogVisible = false;
              this.$emit('refreshTable');
            })
            .catch(err => {
              console.log(err);
              this.$message.error(err.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
